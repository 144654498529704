<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-main" v-loading="loading" element-loading-text="拼命加载中">
      <!--工具条-->
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item>
          <el-input v-model="searchForm.roleName" placeholder="角色名称" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchForm.platformType" placeholder="请选择平台" @change="handleSearch">
            <el-option v-for="item in platFormOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleReset">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="showAddEditDialog()">新增</el-button>
        </el-form-item>
      </el-form>
      <!--列表-->
      <el-table :data="listData">
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="roleName" label="角色名称" width="200"></el-table-column>
        <el-table-column prop="description" label="描述" width="300"></el-table-column>
        <el-table-column prop="platformType" label="平台名称" width="200" :formatter="formatPlatForm"></el-table-column>
        <el-table-column prop="state" label="状态" :formatter="formatState"></el-table-column>
        <el-table-column label="操作" fixed="right" width="220">
          <template slot-scope="scope">
            <el-button size="small" @click="showAddEditDialog(scope.row)">编辑</el-button>
            <el-button v-if="scope.row.state === 1" size="small" type="danger" @click="updateState(scope.row.id, 0)">禁用</el-button>
            <el-button v-else size="small" type="primary" @click="updateState(scope.row.id, 1)">启用</el-button>
            <el-button type="danger" @click="delRole(scope.row.id)" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-col :span="24" class="toolbar">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="10"
          :page-sizes="[10, 20, 30, 50]"
          :total="total"
          style="float:right;"
        >
        </el-pagination>
      </el-col>
    </el-col>
    <!--新增编辑弹框-->
    <el-dialog :title="addEditTitle" :visible.sync="addEditVisible" :close-on-click-modal="false" width="800px">
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form :model="addEditForm" label-width="100px" :rules="addEditFormRules" ref="addEditForm" size="small">
            <el-form-item label="选择平台" prop="platformType">
              <el-select v-model="addEditForm.platformType" placeholder="请选择平台" @change="platformTypeChangeResource" style="width: 100%">
                <el-option v-for="(item, index) in platFormOptions" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="角色名称" prop="roleName">
              <el-input v-model="addEditForm.roleName" placeholder="请输入角色名称" maxlength="20" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="角色描述" prop="description">
              <el-input type="textarea" v-model="addEditForm.description" pplaceholder="请输入描述" :rows="3" maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-tree
            :data="resourceTree.resources"
            show-checkbox
            node-key="id"
            ref="resourceTree"
            :default-expanded-keys="[2, 3]"
            :default-checked-keys="resourceTree.defaultChecked"
            :props="resourceTree.defaultProps"
          >
          </el-tree>
        </el-col>
      </el-row>
      <el-footer style="text-align: center;">
        <el-button @click.native="addEditVisible = false">取消</el-button>
        <el-button type="primary" @click.native="addEditSubmit">保存</el-button>
      </el-footer>
    </el-dialog>
  </el-row>
</template>
<script>
import API from '@/api/system/api_role'
import _ from 'lodash'
import PagesMixins from '@/mixins/pagesMixins'
import { platFormOptions } from '@/config/config'
const searchForm = {
  roleName: null,
  platformType: null
}
const addEditForm = {
  roleName: '',
  platformType: '',
  description: ''
}
export default {
  mixins: [PagesMixins],
  data() {
    return {
      searchForm: _.cloneDeep(searchForm),
      addEditForm: _.cloneDeep(addEditForm),
      platFormOptions,
      listData: [],
      resourceTree: {
        resources: [],
        defaultChecked: [],
        defaultProps: {
          children: 'children',
          label: 'name'
        }
      },
      //编辑相关数据
      addEditTitle: '编辑',
      addEditType: 'edit',
      addEditVisible: false, //编辑界面是否显示
      addEditFormRules: {
        platformType: [{ required: true, message: '请选择平台', trigger: 'change' }],
        roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    formatState(row, column, cellValue) {
      return cellValue ? '启用' : '禁用'
    },
    formatPlatForm(row, column, cellValue) {
      return this.platFormOptions.filter(val => val.value === cellValue).map(cell => cell.label)
    },
    platformTypeChangeResource(platformType) {
      let resourceParam = {}
      if (this.addEditType == 'edit') {
        resourceParam = { roleId: this.addEditForm.id }
      }
      resourceParam.platformType = Number(platformType)
      this.resourceTree.defaultChecked = []
      this.resourceTree.resources = []
      API.resourceTree(resourceParam).then(result => {
        if (result && result.code === '000000') {
          if (result.data.menu.children.length > 0) {
            this.resourceTree.defaultChecked = result.data.ids
            this.resourceTree.resources = result.data.menu.children
          }
        }
      })
    },
    handleReset() {
      this.searchForm = _.cloneDeep(searchForm)
      this.getListData()
    },
    handleSearch() {
      this.total = 0
      this.page = 1
      this.getListData()
    },
    getListData() {
      let params = {
        page: this.page,
        size: this.size,
        ...this.searchForm
      }
      API.findList(params).then(result => {
        if (result && result.code === '000000') {
          this.total = result.data.total
          this.listData = result.data.list
        }
      })
    },
    //删除
    delRole(id) {
      this.$confirm('确认删除该记录吗?', '提示', { type: 'warning' }).then(() => {
        API.remove({ id }).then(result => {
          if (result && result.code === '000000') {
            this.$message.success({ showClose: true, message: '删除成功', duration: 2000 })
            this.getListData()
          }
        })
      })
    },
    //显示新增编辑界面
    showAddEditDialog(row) {
      if (this.$refs['addEditForm'] !== undefined) {
        this.$refs['addEditForm'].resetFields()
      }
      this.resourceTree.defaultChecked = []
      this.resourceTree.resources = []
      if (row) {
        this.addEditTitle = '编辑'
        this.addEditType = 'edit'
      } else {
        this.addEditTitle = '新增'
        this.addEditType = 'add'
      }
      this.addEditForm = Object.assign({}, row ? row : addEditForm)
      if (this.addEditForm.platformType) {
        this.platformTypeChangeResource(this.addEditForm.platformType)
      }
      this.addEditVisible = true
    },
    //新增编辑提交
    addEditSubmit() {
      this.$refs.addEditForm.validate(valid => {
        if (valid) {
          let params = Object.assign({}, this.addEditForm)
          params.menuIds = []
          let checkedNodes = this.$refs.resourceTree.getCheckedNodes(false, false)
          for (let i in checkedNodes) {
            params.menuIds.push(checkedNodes[i].id)
          }
          if (params.menuIds.length === 0) {
            this.$message.error({ showClose: true, message: '请选择菜单', duration: 2000 })
            return
          }
          let response
          if (this.addEditType === 'add') {
            response = API.add(params)
          } else {
            response = API.update(params)
          }
          response.then(result => {
            if (result && result.code === '000000') {
              this.$message.success({ showClose: true, message: this.addEditType == 'add' ? '新增成功' : '编辑成功', duration: 2000 })
              this.addEditVisible = false
              this.getListData()
            } else {
              this.$message.error({ showClose: true, message: result.msg, duration: 2000 })
            }
          })
        }
      })
    },
    //禁用启用
    updateState(id, state) {
      let msg = state === 1 ? '确定要启用此角色吗' : '确定要禁用此角色吗'
      this.$confirm(msg, '提示', { type: 'warning' }).then(() => {
        let params = {
          id,
          state
        }
        API.updateState(params).then(result => {
          if (result && result.code === '000000') {
            this.$message.success({ showClose: true, message: result.msg || '成功', duration: 2000 })
            this.getListData()
          } else {
            this.$message.error({ showClose: true, message: result.msg || '失败', duration: 2000 })
          }
        })
      })
    }
  }
}
</script>
