import * as http from '../http'

export default {
  //查询角色
  findList: params => {
    return http.post('/role/list', params)
  },

  //新增角色
  add: params => {
    return http.post('/role/add', params)
  },

  //更新角色
  update: params => {
    return http.post('/role/update', params)
  },

  // 删除角色
  remove: params => {
    return http.post('/role/delete', params)
  },
  // 更新状态
  updateState: params => {
    return http.post('/role/state', params)
  },
  // 查询角色对应的资源列表
  resourceTree: params => {
    return http.post('/menu/role-tree', params)
  }
}
